import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const candidatePhotoServiceToken: InjectionToken<ICandidatePhotoService> = new InjectionToken('candidatePhotoServiceToken');

export interface ICandidatePhotoService {
    hasPhotoDocument(candidateId: string): boolean;

    getByCandidateId(candidateId: string): string;

    setPhotoDocument(candidateId: string, base64: string): string;

    removePhotoDocument(candidateId: string): boolean;

    getPhotoDocumentById(photoId: string, candidateId: string): Observable<string>;
}
