import { HttpClient } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Params } from '@angular/router';
import { fromEvent, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { candidatePhotoServiceToken, ICandidatePhotoService } from './candidate-photo.provider';

@Injectable()
export class CandidatePhotoService implements ICandidatePhotoService {
    private CANDIDATE_PHOTO_API: string = 'external_personal_profile_picture';

    private candidatesPhotos: Map<string, string> = new Map<string, string>();

    constructor(private http: HttpClient) {
    }

    public getPhotoDocumentById(photoId: string, candidateId: string): Observable<string> {
        if (this.hasPhotoDocument(candidateId)) {
            return of(this.getByCandidateId(candidateId) || '');
        }

        return this.loadPhotoById(photoId, candidateId);
    }

    private loadPhotoById(photoId: string, candidateId: string): Observable<string> {
        const options: Params = {
            body: {id: photoId},
            responseType: 'blob',
        };

        return this.http.request<Blob>('GET', this.CANDIDATE_PHOTO_API, options).pipe(
            mergeMap((blob: Blob) => this.toBase64(blob)),
            map((base64: string) => this.setPhotoDocument(candidateId, base64)),
        );
    }

    private toBase64(blob: Blob): Observable<string> {
        const reader: FileReader = new FileReader();

        reader.readAsDataURL(blob);

        return fromEvent(reader, 'load').pipe(map(() => reader.result as string));
    }

    public hasPhotoDocument(candidateId: string): boolean {
        return this.candidatesPhotos.has(candidateId);
    }

    public removePhotoDocument(candidateId: string): boolean {
        return this.candidatesPhotos.delete(candidateId);
    }

    public setPhotoDocument(candidateId: string, base64: string): string {
        return this.candidatesPhotos.set(candidateId, base64).get(candidateId) || '';
    }

    public getByCandidateId(candidateId: string): string {
        return this.candidatesPhotos.get(candidateId) || '';
    }
}

export const CANDIDATE_PHOTO_SERVICE_PROVIDER: Provider = {
    provide: candidatePhotoServiceToken,
    useClass: CandidatePhotoService,
};
